import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Image from "../components/image";

const IndexPage = () => (
	<Layout>
		<h1>Oh, hello there!</h1>
		<p>Welcome to your new Gatsby site.</p>
		<p>Now go build something great.</p>
		<div style={{ maxWidth: "300px", marginBottom: "1.45rem" }}>
			<Image />
		</div>
		<div className="linkList">
			<Link to="/page-2/">Go to page 2</Link>
			<Link to="/fullPage/">Go to fullPage</Link>
			<Link to="/404/">Go to 404</Link>
			<Link to="/playground/">Go to playground</Link>
		</div>
	</Layout>
);

export default IndexPage;
